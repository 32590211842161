/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { rhythm, scale } from "../utils/typography"

import Header from "./header"
import styled from "styled-components"
import GlobalStyle from "./globalStyle"

const Content = styled.div`
  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  max-width: ${rhythm(35)};
  margin: 0 auto;
  color: #ffffef;
  h1,
  h2,
  h3,
  h4 {
    color: #ffffef;
  }
  a {
    color: rgb(231, 208, 186);
  }
  @media only screen and (max-width: 992px) {
    img {
      padding: 0 0.5em;
    }
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyle />
      <Header siteTitle={data.site.siteMetadata.title} />
      <Content>
        <div>
          <main>{children}</main>
        </div>
      </Content>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
